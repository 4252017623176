import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Back from './components/icons/Back'
// import Nav from './sections/Nav'


function ErrorPage() {

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Goes back to the previous page
    };
    return (
        <div className='w-full'>
            {/* <Nav/> */}
            <div className='w-full h-[100svh]'>
                <div className='w-full h-[100svh] flex flex-col gap-6 items-center justify-center px-16 md:px-28'>
                    <p className='font-bold text-md '>404 error</p>
                    <h1 className='text-3xl sm:text-4xl md:text-5xl font-bold text-center'>We can't find that page</h1>
                    <p className='text-sm text-[#545861]'>Sorry, the page you are looking for doesn't exist or has been moved.</p>
                    <div className='flex flex-col sm:flex-row gap-4'>
                        <button onClick={goBack} className='py-2 px-4 rounded-md font-medium border hover:bg-zinc-50 flex gap-2 items-center shadow-sm'><Back />Go back</button>
                        <Link to='/' className='py-2 px-6 rounded-md font-medium bg-brand-primary hover:bg-brand-secondary text-black shadow-sm'>Take me home</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default ErrorPage