import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/AuthService';

const ProtectedRouteLogin = ({ children }) => {
  const navigate = useNavigate();
  const user = authService.getCurrentUser();

  useEffect(() => {
    if (user && user.user.role === 'buyer') {
        navigate('/userhome'); 
      } else if (user && user.user.role === 'admin') {
        navigate('/admin'); 
      }
    }, [user, navigate]);
  // If user is logged in, render the children
  return !user ? children : null;

};

export default ProtectedRouteLogin;