import { useState, useEffect } from 'react';
import axios from 'axios';

import authService from '../services/AuthService';
import { useNavigate, Link  } from 'react-router-dom';

function UserHome() {

  const [userData, setuserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // 
  // console.log(localStorage.getItem('user'))
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // const user = JSON.parse(localStorage);
        const user = JSON.parse(localStorage.getItem('user')); // Retrieve token from local storage
        const token = user.token;
        // console.log(token)
        // console.log(user.user)
        if (!token) {
          console.log('Token not found in localStorage');
          return;
        }

        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/userhome`, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token
          },
        });
        setuserData(response.data.user);
        console.log(response.data.user)
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch user. Please try again later.');
        setLoading(false);
      }
    };
    fetchUserData();
  }, []);

  const navigate = useNavigate();
  const handleLogout = () => {
    authService.logout();
    navigate('/login'); // Redirect to login page after logout
  };


  return (
    <div className='w-full h-[70vh] bg-white px-16 py-16'>
      <div className='pb-8'>
        <div className='flex flex-row items-center justify-between'>

          {loading ? (
            <>
              <div className='w-48 h-6 bg-gray-300 rounded-md animate-pulse mb-4'></div> {/* Skeleton for the username */}
              <div className='w-20 h-8 bg-gray-300 rounded-md animate-pulse'></div> {/* Skeleton for the button */}
            </>
          ) : (
            userData && (
              <>
                <p className='font-bold text-xl'>Hello, {userData.username}!</p>
                <div className='flex'>
                <button
                  onClick={handleLogout}
                  className='flex items-center text-center w-auto py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-[#D6D7DA] focus:ring-offset-2 bg-none border border-[#D6D7DA] text-[#424650] hover:text-[#28282B]'
                >
                  Logout
                </button>
                  <Link to="/" 
                  className='flex items-center text-center w-auto py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-[#D6D7DA] focus:ring-offset-2 bg-none border border-[#D6D7DA] text-[#424650] hover:text-[#28282B]'>
                    Back to Home
                </Link></div>
               
              </>
            )
          )}
        </div>
      </div>
      <div className='w-full h-[70vh] border'>
        {loading ? (
          <div className='w-48 h-8 bg-gray-300 rounded-md animate-pulse px-8 pt-8'></div> // Skeleton for the Order History heading
        ) : (
          <h1 className='px-8 pt-8 font-bold'>Order History</h1>
        )}

      </div>
    </div>
  )
}

export default UserHome