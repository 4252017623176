import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
const fetchSocialMedia = async () => {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/socials`);
    return response.data;
};
function Social() {
    const { data: socialMediaData, error, isLoading } = useQuery({
        queryKey: ['dataKeySocial'],  // The unique key for this query
        queryFn: fetchSocialMedia,     // Function to fetch the beats
        staleTime: 1000 * 60 * 5,  // 5 minutes, data will stay fresh for 5 minutes
        cacheTime: 1000 * 60 * 10, // 10 minutes, the cache will be kept for 10 minutes
        refetchOnWindowFocus: false,  // Disable refetching on window focus
        refetchOnReconnect: false,    // Disable refetching on reconnect
    });
    return (
        <div className='bg-white text-sm'>
            <div className="rounded-lg max-h-[70vh] overflow-hidden">
                <div className="overflow-y-auto max-h-[70vh]">
                    <table className="min-w-full border-collapse">
                        <thead className="bg-lightgray-primary text-xs text-[#535862] sticky top-0 z-10">
                            <tr>
                                {isLoading ? (
                                    /** Skeleton Header Cells */
                                    Array.from({ length: 3 }).map((_, index) => (
                                        <th key={index} className="px-2 py-3 text-left">
                                            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                                        </th>
                                    ))
                                ) : (
                                    <>
                                        <th className="px-2 py-3 text-left">Label</th>
                                        <th className="px-2 py-3 text-left">URL</th>
                                        <th className="px-2 py-3 text-left">Icon</th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                /** Skeleton Loading Rows */
                                Array.from({ length: 5 }).map((_, index) => (
                                    <tr key={index} className="bg-white border-b">
                                        <td className="p-4">
                                            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                                        </td>
                                        <td className="p-4">
                                            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                                        </td>
                                        <td className="p-4">
                                            <div className="h-4 bg-gray-200 rounded-full animate-pulse"></div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <>
                                    {
                                        // Inline conditional rendering
                                        !socialMediaData || socialMediaData.length === 0 ? (
                                            <tr>
                                                <td colSpan="3" className="p-4 text-center">No social media data available</td>
                                            </tr>
                                        ) : (
                                            /** Data Rows */
                                            socialMediaData.map((item, index) => (
                                                <tr key={index} className="bg-white hover:bg-lightgray-primary text-xs text-[#535862] border-b">
                                                    <td className="p-4 text-[#181D27] font-bold">{item.label}</td>
                                                    <td className="p-4">
                                                        <a href={item.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                                                            {item.url}
                                                        </a>
                                                    </td>
                                                    <td className="p-4">
                                                        <div className="icon" aria-hidden="true">{item.icon}</div>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                    }
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default Social