import React, { useState, useRef, useEffect } from 'react';


import Nav from './sections/Nav';
import Hero from './sections/Hero';
import './App.css';
import Player from './sections/Player';
import PlayerFloat from './sections/PlayerFloat';
import Footer from './sections/Footer';
import Pricing from './sections/Pricing';
import Cart from './sections/Cart';
import { Toaster, toast } from 'sonner';
import CheckNotifcation from './components/icons/CheckNotifcation.js'
import WarningNotification from './components/icons/WarningNotification.js';
import ShareableLink from './sections/ShareableLink'
import axios from 'axios';

function App() {
  const [show, setShow] = useState(true)
  const [cart, setCart] = useState([])
  // const [warning, setWarning] = useState(false)
  console.log('Server URL:', process.env.REACT_APP_SERVER_URL);
  //Player & setBeats
  const [currentAudio, setCurrentAudio] = useState(null);
  const audioRef = useRef(null);
  const [audioIsPlaying, setAudioIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(.4); 
  //Shareable link
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [itemShareableLink, setItemShareableLink] = useState(null)
  const [beats, setBeats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBeats = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/beats`);
        setBeats(response.data);
        console.log(response.data)
        // setLoading(false);
      } catch (err) {
        setError('Failed to fetch beats. Please try again later.');
        // setLoading(false);
      }
    };
    fetchBeats();
  }, []);

//   const fetchBeats = async () => {
//     const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/beats`);
//     return response.data;
// };

//   const { data: beats, error, isLoading } = useQuery({
//     queryKey: ['dataKeySocial'],  // The unique key for this query
//     queryFn: fetchBeats,     // Function to fetch the beats
//     staleTime: 1000 * 60 * 5,  // 5 minutes, data will stay fresh for 5 minutes
//     cacheTime: 1000 * 60 * 10, // 10 minutes, the cache will be kept for 10 minutes
//     refetchOnWindowFocus: false,  // Disable refetching on window focus
//     refetchOnReconnect: false,    // Disable refetching on reconnect
// });
  
  // useEffect(() => {
  //   beats.forEach((item) => {
  //     const audio = new Audio(`${item.mp3Url}`);
  //     audio.addEventListener('loadedmetadata', () => {
  //       setSongDurations((prevDurations) => ({
  //         ...prevDurations,
  //         [item._id]: audio.duration 
  //       }));
  //     });
  //   });
  // }, []);
  const playSound = (mp3Url, item) => {
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
          setAudioIsPlaying(false);
        }
        const audioPath = `${mp3Url}`;
        const audio = new Audio(audioPath);
        audioRef.current = audio;
        audioRef.current.volume = volume; // Set the volume to the current volume state
        setCurrentAudio(item);  // Set the current playing song
        // Listen for time updates to get the current time
        audio.addEventListener('timeupdate', () => {
          setCurrentTime(audio.currentTime);  // Update current time
        });
        audio.onended = () => {
          audioRef.current = null;
          setAudioIsPlaying(false);
          setCurrentTime(0); // Reset time when the audio ends
          playNextSong();
        };
        audio.play()
          .then(() => {
            setAudioIsPlaying(true);
          })
          .catch((error) => {
            console.error('Error playing audio:', error);
          });
  };
  // Function to play the next song
  const playNextSong = () => {
    if (currentAudio) {
      const currentIndex = beats.findIndex(item => item._id === currentAudio._id);
      const nextIndex = (currentIndex + 1) % beats.length; // Loop to the start
      const nextSong = beats[nextIndex];
      playSound(nextSong.mp3Url, nextSong);
    }
  }
  //Add to cart
  const addToCart = (item) => {
    var isPresent = false;
    cart.forEach((product) => {
      // console.log(item.id, product.id)
      if (item._id === product._id) {
        isPresent = true
      } else {
      }
    })
    if (isPresent) {
      // setWarning(true);
      toast(' Item is already added to cart', { className: '', duration: 1000, icon: <WarningNotification />, });
      setTimeout(() => {
        // setWarning(false)
      }, 500)
      return;
    } else {
      toast(' Successfully Added!', { className: '', duration: 1000, icon: <CheckNotifcation />, });
      // setCart([...cart, item])
      setCart(prevCart => [...prevCart, { ...item, price: item.price || 30 }]);
    }
  }
 // Function to handle the div click
 const sharePopup = (item) => {
    setIsPopupVisible(!isPopupVisible); // Toggle the popup
    setItemShareableLink(item)
     console.log("sharePopup")
  };
  // Function to close the pop-up
  const closePopup = () => {
    setIsPopupVisible(!isPopupVisible);
    console.log("closePopup")
  };
  useEffect(() => {
    document.body.style.overflow = isPopupVisible ? "hidden" : "unset";
  }, [isPopupVisible]);
  return (

    <div className="App">
        
      <Nav size={cart.length} cart={cart} setCart={setCart} setShow={setShow} />
      {
        show ? (
          <>
            <Hero />
            <Player 
              currentAudio={currentAudio} 
              beats={beats} 
              addToCart={addToCart} 
              playSound={playSound} 
              duration={beats.duration} 
              audioRef={audioRef} 
              volume={volume} 
              audioIsPlaying={audioIsPlaying} 
              setAudioIsPlaying={setAudioIsPlaying} 
              setVolume={setVolume} 
              currentTime={currentTime}
              cart={cart}
              sharePopup={sharePopup} 
            />
            <Pricing />
            <Footer />
          </>
        ) : (
          <>
            <Cart cart={cart} setCart={setCart} />
          </>
        )
      }
     <PlayerFloat 
      currentAudio={currentAudio} 
      beats={beats} 
      playNextSong={playNextSong} 
      playSound={playSound} 
      duration={beats.duration} 
      audioRef={audioRef} 
      volume={volume} 
      audioIsPlaying={audioIsPlaying} 
      setAudioIsPlaying={setAudioIsPlaying} 
      setVolume={setVolume} 
      currentTime={currentTime}
    />
      <Toaster position="top-center" />
      {isPopupVisible && (
          <ShareableLink 
          closePopup={closePopup} 
          itemShareableLink={itemShareableLink}
          />
         )
      }

    </div>
 
  )
}
export default App;
