import { useState } from "react";
function CopyToClipboard({ text }) {
  const [isCopied, setIsCopied] = useState(false)
  const copyToClipboard = async () => {
    if (text) {
      try {
        await navigator.clipboard.writeText(text)
        setIsCopied(true)
        setTimeout(() => setIsCopied(false), 2000)
      } catch (err) {
        console.error("Failed to copy text: ", err)
        alert("Failed to copy text. Please try again.")
      }
    }
  }
  return (
    <button
      onClick={copyToClipboard}
      disabled={!text}
      className={`w-full text-black  bg-brand-zinc-100 hover:bg-brand-zinc-50 focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2.5 text-center flex flex-row  justify-center align-center${text ? 'bg-brand-zinc-200 hover:bg-brand-zinc-100' : 'bg-gray-300 cursor-not-allowed'
        }`}
    >
      {isCopied ? (
        <>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 6L9 17L4 12" stroke="#181D27" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          Copied!
        </>
      ) : (
        <>
          <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
          </svg>
          Copy to Clipboard
        </>
      )}
    </button>
  )
}
export default CopyToClipboard