import React from 'react'

function Search({searchTerm,setSearchTerm}) {
  return (
    <input
    type='textfield'
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
    className='bg-[#f8f8f8] text-[#424650] py-2 px-1 rounded-md flex-grow text-sm px-4 focus:ring-[#D6D7DA]'
    placeholder='Search by title or tag'

/>
  )
}

export default Search