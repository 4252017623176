import React, { useState } from 'react';
import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'
import FileUpload from './pages/FileUpload.js'
import BeatList from './pages/BeatList.js'
import Dashboard from './pages/Dashboard.js'
import Settings from './pages/Settings.js'
import Pricing from './pages/Pricing.js'
import Slider from './pages/Slider.js'
import Social from './pages/Social.js'
import Users from './pages/Users.js'
import Logo from './components/Logo'

import FileUploadIcon from './icons/FileUpload.js';
import BeatListIcon from './icons/BeatList.js';
import DashboardIcon from './icons/Dashboard.js';
import SettingsIcon from './icons/Settings.js';
import ViewSiteIcon from './icons/ViewSite.js';
import PricingIcon from './icons/Pricing.js';
import SliderIcon from './icons/Slider.js';
import SocialIcon from './icons/Social.js';
import UsersIcon from './icons/Users.js';
import ViewPageIcon from './icons/ViewPage.js';


import { useNavigate } from 'react-router-dom';
import authService from '../services/AuthService';

const pages = [
  { id: 1, name: 'Dashboard', path: '/admin/Dashboard', content: <Dashboard />, icon: <DashboardIcon />, description: 'Overview of key metrics and quick links for managing content.' },
  { id: 2, name: 'Beat Upload', path: '/admin/fileupload', content: <FileUpload />, icon: <FileUploadIcon isButton={false} />, description: 'Interface for uploading and configuring new beats.' },
  { id: 3, name: 'Beat List', path: '/admin/musiclist', content: <BeatList />, icon: <BeatListIcon />, description: 'Comprehensive list of all beats for easy editing and management.' },
  { id: 4, name: 'Pricing', path: '/admin/pricing', content: <Pricing />, icon: <PricingIcon />, description: 'Section for updating pricing plans and features.' },
  { id: 5, name: 'Slider', path: '/admin/slider', content: <Slider />, icon: <SliderIcon />, description: 'Manage featured content and promotional displays.' },
  { id: 7, name: 'Social', path: '/admin/social', content: <Social />, icon: <SocialIcon />, description: 'Configure social media integrations and sharing options.' },
  { id: 8, name: 'Settings', path: '/admin/settings', content: <Settings />, icon: <SettingsIcon />, description: 'pdate system preferences and account settings.' },
  { id: 9, name: 'Users', path: '/admin/users', content: <Users />, icon: <UsersIcon />, description: 'Administer user accounts and permissions.' },
]
const queryClient = new QueryClient()

function Admin() {
  const [selectedPage, setSelectedPage] = useState(pages[0]);
  const handlePageClick = (page) => {
    setSelectedPage(page);
  };
  const navigate = useNavigate();
  const handleLogout = () => {
    authService.logout();
    navigate('/login'); // Redirect to login page after logout
  };


  return (
    <QueryClientProvider client={queryClient}>
      <div className="flex min-h-screen bg-zinc-100">
        {/* Sidebar */}
        <div className="w-64 text-primary-200 px-6 py-6 overflow-auto m-4 bg-white  rounded-md">
          <div className='flex flex-col align-between justify-between h-full'>
            <div >
              <div className=' flex items-center px-2 pt-0 pb-8 gap-3 font-bold'>
             <Logo/>
              </div>
          
              <nav>
                <ul className="flex flex-col gap-0 ">
                  {pages.map((page, index) => (
                    <>{page.name === 'Settings' && <div className='border-t pt-4 mt-4'></div>}
                      <li key={page.id}  >
                        <button
                          onClick={() => handlePageClick(page)}
                          className={`rounded w-full text-sm text-left px-2 py-2 
                          hover:bg-lightgray-primary  transition-colors 
                          duration-200 text-nowrap overflow-clip 
                          hover:overflow-scroll   ${selectedPage.id === page.id ? 'bg-[#FAFAFA] text-[#262B36]' : 'text-[#535862]'
                            }`}
                        >
                          <div className='flex gap-4  items-center'>
                            <div className='text-[#72767F]'>{page.icon}</div>
                            <div className={`${selectedPage.id === page.id ? 'text-[#262B36]' : 'text-[#424650]'}`}>{page.name}</div>
                          </div>
                        </button>
                      </li>
                    </>
                  ))}
                </ul>
              </nav>
            </div>
            <div className='rounded  text-sm text-left 
                         text-nowrap overflow-clip 
                        hover:overflow-scroll 
                        gap-2 flex flex-col
                        '>
              <a href="/" target="_blank" className='flex justify-between border px-2 py-2  hover:bg-lightgray-primary  transition-colors 
                        duration-200'>
                <div className='flex gap-4 text-[#72767F]'>  
                  <ViewPageIcon />View Site
                  </div>
                 <div className='flex gap-4 text-[#72767F]'>  
                <ViewSiteIcon />
                </div>
              </a>
              <button onClick={handleLogout}  className='flex items-center text-center w-full py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-[#D6D7DA] focus:ring-offset-2  bg-none border border-[#D6D7DA] text-[#424650] hover:text-[#28282B]'>Logout</button>
      
            </div>
          
       </div>
         
        </div>
       
        {/* Main Content Area */}
        <div className="flex-1 my-4 mr-4  bg-white rounded-lg ">
      
          {/* border */}
          <div className='px-8 pt-4 pb-4 flex flex-col gap-2 bg-white w-full  rounded-lg focus:outline-none '>
            <h1 className="text-xl font-bold text-[#191D26]">{selectedPage.name}</h1>
            <p className="text-sm text-[#545861]">{selectedPage.description}</p>
          </div>
          <p className="text-primary-200  px-8 pt-0 pb-8  ">{selectedPage.content}</p>
        </div>
      </div>
    </QueryClientProvider>
  );
}
export default Admin;