import { useState } from "react";
import CopyToClipboard from "./CopyToClipboard";

function ShareableLink({ closePopup, itemShareableLink }) {
  const [text, setText] = useState(`${process.env.REACT_APP_SERVER_URL}/beat/${itemShareableLink._id}`)

  return (
    <div>
      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 backdrop-blur-lg"
        onClick={closePopup} // Close pop-up when clicking on the background
      >
        <div
          className="w-full max-w-lg h-auto max-h-screen p-8 rounded-lg shadow-lg  max-w-[425px]"
          onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the pop-up
        >
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-brand-white rounded-lg shadow-xl w-full max-w-md">
              <div className="flex items-start justify-between p-5 border-b border-gray-200 rounded-t">
                <div className='flex gap-5'>
                  <div>
                    <img src={itemShareableLink.thumbnailUrl} className="w-[3em] aspect-square rounded-sm  flex" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-gray-900">
                      {itemShareableLink.title}
                    </h3>
                    <p className="text-xs text-brand-zinc-400">{itemShareableLink.tags}</p>
                  </div>
                </div>
                <button
                  onClick={closePopup}
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                >
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                  </svg>
                </button>
              </div>
              <div className="p-6 space-y-6">
                <label class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-right" for="name">Shareable Link</label>
                <input class="flex h-9 w-full 
              rounded-md border border-input bg-transparent px-3 
              py-1 text-sm  transition-colors file:border-0 
              file:bg-transparent file:text-sm file:font-medium file:text-foreground 
              placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 
              focus-visible:ring-ring disabled:cursor-not-allowed 
              disabled:opacity-50 col-span-3
              "
                  id="name"
                  value={`http://localhost:3000/beat/${itemShareableLink._id}`} />
              </div>
              <div className="flex items-center justify-start p-6 border-t border-gray-200 rounded-b">

                <CopyToClipboard text={text} />
              </div>
            </div>
          </div>
     </div>
      </div>
    </div>
  )
}
export default ShareableLink