import React from 'react'
import EditIcon from '../icons/Edit';

function Edit({isUpdating}) {
  return (
    <button
    type="submit"
    className={`py-2 px-4 rounded-md font-semibold focus:outline-none focus:ring-2 focus:ring-[#D6D7DA] focus:ring-offset-2 ${isUpdating
        ? 'bg-[#E9D7FE] text-white cursor-not-allowed'
        : 'bg-none border border-[#D6D7DA] text-[#424650] hover:text-[#28282B]'
        }`}
    disabled={isUpdating}
>
    {isUpdating ? 'Updating...' : <div className='flex gap-2 items-center text-sm'><EditIcon /><p className='hidden md:flex '>Edit</p></div>}
</button>
  )
}

export default Edit