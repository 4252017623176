import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import Clock from '../icons/Clock'

import Edit from '../components/Edit';
import Add from '../components/Add';
import Delete from '../components/Delete';
import Search from '../components/Search';

const fetchBeats = async () => {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/beats`);
    return response.data;
};
function MusicList() {
    const [checkedItems, setCheckedItems] = useState({});
    const [isUploading, setIsUploading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const handleCheckboxChange = (id) => {
        setCheckedItems((prev) => ({ ...prev, [id]: !prev[id], }));
    };
    const { data: beats, error, isLoading } = useQuery({
        queryKey: ['dataKeyBeats'],  // The unique key for this query
        queryFn: fetchBeats,     // Function to fetch the beats
        staleTime: 1000 * 60 * 5,  // 5 minutes, data will stay fresh for 5 minutes
        cacheTime: 1000 * 60 * 10, // 10 minutes, the cache will be kept for 10 minutes
        refetchOnWindowFocus: false,  // Disable refetching on window focus
        refetchOnReconnect: false,    // Disable refetching on reconnect
    });
    console.log(beats,'test')



    // Filter songs based on search input, use optional chaining in case beats is undefined
    const filteredSongs = beats?.filter((beat) => {
        const tagsAsString = Array.isArray(beat.tags) ? beat.tags.join(' ').toLowerCase() : beat.tags.toLowerCase();
        return beat.title.toLowerCase().includes(searchTerm.toLowerCase()) || tagsAsString.includes(searchTerm.toLowerCase());
    }) || [];



    return (
        <div>
            <div className=" max-h-[75vh] ">


                <div className='w-full flex  flex grow items-end justify-between pb-4 space-x-4 '>
                    <Search setSearchTerm={setSearchTerm} searchTerm={searchTerm} />
                    <Add isUploading={isUploading} />
                    <Edit isUpdating={isUpdating} />
                    <Delete isDeleting={isDeleting} />
                </div>
                <div className="overflow-y-auto max-h-[70vh] ">

                    <table className="min-w-full border-collapse ">
                        <thead className="bg-white text-xs text-[#72767F] sticky top-0 z-10 bg-[#FAFAFA] ">
                            <tr>
                                {isLoading ? (
                                    /** Skeleton Header Cells */
                                    Array.from({ length: 8 }).map((_, index) => (
                                        <th key={index} className="px-2 py-3 text-left">
                                            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                                        </th>
                                    ))
                                ) : (
                                    <>
                                        <th className="px-2 py-3 text-center w-1/12 ">Select</th>
                                        <th className="px-2 py-3 text-left w-1/12 ">Thumbnail</th>
                                        <th className="px-2 py-3 text-left w-2/12">Title</th>
                                        {/* <th className="px-2 py-3 text-left w-auto">ID</th> */}
                                        <th className="px-2 py-3 text-left w-2/12 ">Tags</th>
                                        <th className="px-2 py-3 text-center w-1/12 ">Duration</th>
                                        <th className="px-2 py-3 text-center w-1/12 ">BPM</th>
                                        <th className="px-2 py-3 text-left w-1/12">Status</th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                /** Skeleton Loading Rows */
                                Array.from({ length: 9 }).map((_, index) => (
                                    <tr key={index} className="bg-white border-b">
                                        <td className="p-2">
                                            <div className="h-5 w-5 bg-gray-200 rounded-md animate-pulse"></div>
                                        </td>
                                        <td className="p-2  hidden md:flex">
                                            <div className="w-8 h-8 bg-gray-200 rounded animate-pulse"></div>
                                        </td>
                                        {/* <td className="p-2">
                                            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                                        </td> */}
                                        <td className="p-2">
                                            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                                        </td>
                                        <td className="p-2 ">
                                            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                                        </td>
                                        <td className="p-2 ">
                                            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                                        </td>
                                        <td className="p-2 ">
                                            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                                        </td>
                                        <td className="p-2">
                                            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <>
                                    {filteredSongs.length > 0 ? (
                                        filteredSongs.map((beat) => (
                                            <tr key={beat.id} className="hover:bg-lightgray-primary text-xs text-[#535862] border-b odd:bg-[#FFFFFF] even:bg-[#FDFDFD]">
                                                <td className="p-2">
                                                    <label className="flex items-center justify-center space-x-3 cursor-pointer">
                                                        <input
                                                            type="checkbox"
                                                            checked={!!checkedItems[beat._id]}
                                                            onChange={() => handleCheckboxChange(beat._id)}
                                                            className="appearance-none h-5 w-5  border border-gray-300 rounded-md checked:bg-secondary-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 checked:after:content-['✓'] checked:after:text-white checked:after:absolute checked:after:left-1 checked:after:top-1 checked:after:text-lg"
                                                        />
                                                    </label>
                                                </td>
                                                <td className="p-2 ">
                                                    <img src={beat.thumbnailUrl} alt={beat.title} className="w-8 h-8 rounded" />
                                                </td>
                                                <td className="p-2 text-[#545861]">{beat.title}</td>
                                                {/* <td className="p-2 text-[#545861]">{beat._id}</td> */}
                                                {/* <td className="p-2">
                                                    <p className='text-[#365ACD] border bg-[#F1F8FF] border-[#BCDCFD] rounded-xl inline py-1 px-2'>
                                                        {beat.tags}
                                                    </p>
                                                </td> */}
                                                <td className="p-2 ">
                                                    <p
                                                        className={`
                                                            ${beat.tags == 'Drake type beat' ? 'text-[#365ACD] bg-[#F1F8FF] border-[#BCDCFD]' : ''}
                                                            ${beat.tags == 'Rich Brian Type Beat' ? 'text-[#6742C0]  bg-[#F9F5FF] border-[#E7D8FC]' : ''}
                                                            ${beat.tags == '50 cent type beat' ? 'text-[#3D36C7]  bg-[#F0F4FE] border-[#CBD6FB]' : ''}
                                                            ${beat.tags == 'Neyo Type Beat' ? 'text-[#33744B]  bg-[#EFFDF4] border-[#B3E6C3]' : ''}
                                                            ${beat.tags == 'Chris Brown Type Beat' ? 'text-[#B12E72]  bg-[#F8EFF7] border-[#ECC9E3]' : ''}
                                                            ${beat.tags == 'Travis Scott Type Beat' ? 'text-[#CD7025]  bg-[#F9F1E7] border-[#EED4B6]' : ''}
                                                            ${beat.tags == 'Lil wayne type beat' ? 'text-[#31689E]  bg-[#F2F9FF] border-[#C3E5FC]' : ''}
                                                            ${beat.tags == 'Sza type beat' ? 'text-[#5825D5]  bg-[#F4F3FE] border-[#DAD6FC]' : ''}
                                                            ${beat.tags == 'Akon Type Beat' ? 'text-[#3A3F6F]  bg-[#F9F9FC] border-[#D6D9EA]' : ''}
                                                            ${beat.tags == 'The Weekend Type Beat' ? 'text-[#A7501C]  bg-[#FEFAED] border-[#F8E193]' : ''}
                                                            ${beat.tags == 'tyga type beat' ? 'text-[#A53421]  bg-[#FCF4F2] border-[#F6CFCB]' : ''}
                                                            border rounded-xl inline py-1 px-2
                                                          
                                                        `}
                                                    >
                                                        {beat.tags}
                                                    </p>
                                                </td>

                                                <td className="p-4 flex gap-2 items-center text-[#424650]">
                                                    <div className="text-[#545861]">
                                                        <Clock />
                                                    </div>
                                                    {beat.duration}
                                                </td>
                                                <td className="p-2 text-[#545861] text-center">{beat.bpm}</td>
                                                <td className="p-2">
                                                    <span className={`inline-block px-2 py-1 text-xs flex items-center gap-2 rounded-md text-nowrap
                                                        ${beat.status === 'sold' ? 'bg-none text-[#545861] border-[#D6D7DA] border' : 'bg-none text-[#545861] border-[#D6D7DA] border'}`}>
                                                        {/* ${beat.status === 'sold' ? 'bg-[#F8EFF7] text-[#B12E72] border-[#ECC9E3] border' : 'bg-[#EFFCF4] text-[#387952] border-[#B6E9C6] border'}`}> */}
                                                        <div className={`rounded-2xl h-2 w-2 border bg-grey-100 ${beat.status === 'sold' ? 'bg-[#AF5254]' : 'bg-[#52AF70]'}`}></div>
                                                        {beat.status}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <td>
                                            <div className="text-center py-4 w-[70.6vw] absolute ">
                                                <p className="text-zinc-500 text-lg ">No result found</p>
                                            </div> </td>
                                    )}

                                </>
                                /** Data Rows */
                                // beats && beats.map((beat) => (

                                // ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default MusicList