import React from 'react';

const Dashboard = () => {

  return (
    <div className='bg-white text-sm'>
      <h2>Dashboard</h2>

    </div>
  );
};

export default Dashboard;
