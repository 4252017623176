import React from 'react'
function WarningNotification() {
  return (
    <div>
      {/* <svg className='text-yellow-400 hover:text-black size-6' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
</svg> */}
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#FEF0C7" />
        <path d="M11.9999 9.99998V12M11.9999 14H12.0049M11.1449 7.42998L6.90989 14.5C6.82257 14.6512 6.77637 14.8226 6.77588 14.9972C6.77539 15.1719 6.82063 15.3435 6.9071 15.4952C6.99357 15.6469 7.11825 15.7734 7.26874 15.8619C7.41923 15.9505 7.59029 15.9981 7.76489 16H16.2349C16.4095 15.9981 16.5805 15.9505 16.731 15.8619C16.8815 15.7734 17.0062 15.6469 17.0927 15.4952C17.1791 15.3435 17.2244 15.1719 17.2239 14.9972C17.2234 14.8226 17.1772 14.6512 17.0899 14.5L12.8549 7.42998C12.7658 7.28303 12.6402 7.16154 12.4905 7.07722C12.3407 6.9929 12.1718 6.94861 11.9999 6.94861C11.828 6.94861 11.6591 6.9929 11.5093 7.07722C11.3595 7.16154 11.234 7.28303 11.1449 7.42998Z" stroke="#DC6803" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
  )
}
export default WarningNotification