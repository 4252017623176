import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Cart from './sections/Cart.js'
import Contact from './sections/Contact.js'
import Pricing from './sections/Pricing.js'
import Login from './auth/Login.js'
import Beat from './sections/Beat.js'
import Sidebar from './admin/Sidebar.js'
import UserHome from './user/UserHome.js'
import ErrorPage from './ErrorPage.js'

import Register from './sections/Register.js'; // Import the Register component
import ProtectedRouteBuyer from './components/ProtectedRouteBuyer.js'; // Import the ProtectedRoute component
import ProtectedRouteAdmin from './components/ProtectedRouteAdmin.js'; // Import the ProtectedRoute component
import ProtectedRouteLogin from './components/ProtectedRouteLogin.js'; // Import the ProtectedRoute component

const router = createBrowserRouter([
  { path: "/", element: <App />, errorElement: <ErrorPage /> },
  { path: "/cart", element: <Cart /> },
  { path: "/contact", element: <Contact /> },
  { path: "/pricing", element: <Pricing /> },
  { 
    path: "/login", 
    element: (
      <ProtectedRouteLogin>
      <Login />
      </ProtectedRouteLogin>)
  },
  { path: "/register", element: <Register /> },
  { path: "/beat/:_id", element: <Beat /> },
  { 
    path: "/admin", 
    element: (
      <ProtectedRouteAdmin>
        <Sidebar />
      </ProtectedRouteAdmin>
    ) 
  },
  { 
    path: "/userhome", 
    element: (
      <ProtectedRouteBuyer>
        <UserHome />
      </ProtectedRouteBuyer>
    ) 
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
    {/* <App /> */}
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
