import React from 'react'

function Slider() {
  return (
    <div className='bg-white text-sm'>

      Slider</div>
  )
}

export default Slider