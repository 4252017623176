import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
const fetchPricing = async () => {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/pricing`);
    return response.data;
};
function Pricing() {
    const { data: pricingData, error, isLoading } = useQuery({
        queryKey: ['dataKeyPricing'],  // The unique key for this query
        queryFn: fetchPricing,     // Function to fetch the beats
        staleTime: 1000 * 60 * 5,  // 5 minutes, data will stay fresh for 5 minutes
        cacheTime: 1000 * 60 * 10, // 10 minutes, the cache will be kept for 10 minutes
        refetchOnWindowFocus: false,  // Disable refetching on window focus
        refetchOnReconnect: false,    // Disable refetching on reconnect
    });
    return (
        <div className='bg-white text-sm'>
            <div className="rounded-lg max-h-[70vh] overflow-hidden">
                <div className="overflow-y-auto max-h-[70vh]">
                    <table className="min-w-full border-collapse">
                        <thead className="bg-lightgray-primary text-xs text-[#535862] sticky top-0 z-10">
                            <tr>
                                {isLoading ? (
                                    /** Skeleton Header Cells */
                                    Array.from({ length: 7 }).map((_, index) => (
                                        <th key={index} className="px-2 py-3 text-left">
                                            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                                        </th>
                                    ))
                                ) : (
                                    <>
                                        <th className="px-2 py-3 text-left">Title</th>
                                        <th className="px-2 py-3 text-left">Description</th>
                                        <th className="px-2 py-3 text-left">Currency</th>
                                        <th className="px-2 py-3 text-left">Price</th>
                                        <th className="px-2 py-3 text-left">List</th>
                                        <th className="px-2 py-3 text-left">Most Popular</th>
                                        <th className="px-2 py-3 text-left">Promo</th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                /** Skeleton Loading Rows */
                                Array.from({ length: 5 }).map((_, index) => (
                                    <tr key={index} className="bg-white border-b">
                                        <td className="p-4">
                                            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                                        </td>
                                        <td className="p-4">
                                            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                                        </td>
                                        <td className="p-4">
                                            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                                        </td>
                                        <td className="p-4">
                                            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                                        </td>
                                        <td className="p-4">
                                            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                                        </td>
                                        <td className="p-4">
                                            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                                        </td>
                                        <td className="p-4">
                                            <div className="h-4 bg-gray-200 rounded animate-pulse"></div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                /** Data Rows */
                                pricingData && pricingData.map((item, index) => (
                                    <tr key={index} className="bg-white hover:bg-lightgray-primary text-xs text-[#535862] border-b">
                                        <td className="p-4 text-[#181D27] font-bold">{item.title}</td>
                                        <td className="p-4">{item.description}</td>
                                        <td className="p-4">{item.currency}</td>
                                        <td className="p-4">{item.price}</td>
                                        <td className="p-4">{item.list.join(', ')}</td>
                                        <td className="p-4">{item.most_popular ? 'Yes' : 'No'}</td>
                                        <td className="p-4">
                                            {item.promo[0] ? (
                                                <span className="inline-block px-2 py-1 text-xs font-semibold bg-green-100 text-green-700 border border-green-400 rounded-full">
                                                    {item.promo[1]}
                                                </span>
                                            ) : (
                                                <span className="inline-block px-2 py-1 text-xs font-semibold bg-gray-200 text-gray-600 rounded-full">
                                                    No Promo
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default Pricing