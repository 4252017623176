import { useState } from 'react';
import axios from 'axios';
import { Toaster, toast } from 'sonner';
import CheckNotifcation from '../../components/icons/CheckNotifcation.js'
import ErrorNotifcation from '../../components/icons/ErrorNotifcation.js'
import FileUploadIcon from '../icons/FileUpload.js'
import Remove from '../../components/icons/Remove.js'
const FileUpload = () => {
  const [formData, setFormData] = useState({
    title: '',
    thumbnail: null,
    mp3: null,
    tags: '',
    duration: '',
    bpm: '',
    status: ''
  });
  const [isUploading, setIsUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    console.log(value)
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUploading(true);
    setUploadStatus(null);
    const uploadData = new FormData();
    for (const key in formData) {
      uploadData.append(key, formData[key]);
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/upload`, uploadData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setFormData({
        title: '',
        thumbnail: null,
        mp3: null,
        tags: '',
        bpm: '',
        status: ''
      });
      e.target.thumbnail.value = '';
      e.target.mp3.value = '';
      console.log(formData)
    } catch (error) {
      console.error('Upload error:', error);
      toast('Failed to upload beat. Please try again.', { className: '', duration: 2000, icon: <ErrorNotifcation />, });
      setIsUploading(false);
    } finally {
      toast('Successfully Added!', { className: '', duration: 2000, icon: <CheckNotifcation />, });
      setUploadStatus({ type: 'success', message: 'Beat uploaded successfully!' });
      setIsUploading(false);
    }
  };
  const [fileNameThumbnail, setFileNameThumbnail] = useState('');
  const [fileNameMp3, setFileNameMp3] = useState('');
  const handleFileUploadChange = (e) => {
    const { name, files } = e.target;
    if (name === 'thumbnail') { setFileNameThumbnail(e.target.files[0].name); }
    if (name === 'mp3') { setFileNameMp3(e.target.files[0].name); }
    setFormData(prevState => ({
      ...prevState,
      [name]: files[0]
    }));
  };
  const clearFileThumbnail = () => {
    setFileNameThumbnail('');
    document.getElementById('thumbnail').value = '';
  };
  const clearFileMp3 = () => {
    setFileNameMp3('');
    document.getElementById('mp3').value = '';
  };
  return (
    <div className='bg-white text-sm'>
      <div className="max-w-md  p-0 ">
        {/* {uploadStatus && (
        <div className={`mb-0 p-2 rounded ${uploadStatus.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
          {uploadStatus.message}
        </div>
      )} */}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              placeholder='Title'
              className="w-full px-3 py-2   border border-gray-300  rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className='flex gap-4'>
            {/* <label htmlFor="thumbnail" className="block text-sm font-medium text-gray-700 mb-1">Thumbnail</label> */}
            {/* <input
              type="file"
              id="thumbnail"
              name="thumbnail"
              onChange={handleFileChange}
              accept="image/*"
              className="w-full px-3 py-2   border border-gray-300  rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            /> */}
            <div className="w-1/2 max-w-md mx-auto">
              <label
                htmlFor="thumbnail"
                className={`flex items-center justify-center w-full h-12 px-4 transition duration-300 ease-in-out   border border-gray-300  rounded-md cursor-pointer ${fileNameThumbnail ? ' text-zinc-500' : 'bg-white hover:bg-gray-50'
                  }`}
              >
                {fileNameThumbnail ? (
                  <>
                    <span className="text-sm truncate flex-1">{fileNameThumbnail}</span>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        clearFileThumbnail();
                      }}
                      className="ml-2 focus:outline-none"
                    >
                      <Remove className="w-5 h-5" />
                    </button>
                  </>
                ) : (
                  <div className='flex gap-4 items-center'>
                    <FileUploadIcon isButton={true} className="w-5 h-5 mr-2" />
                    <span className="text-sm">Upload Image</span>
                  </div>
                )}
                <input
                  type="file"
                  id="thumbnail"
                  name="thumbnail"
                  onChange={handleFileUploadChange}
                  accept="image/*"
                  className="hidden"
                  required
                />
              </label>
            </div>
            <div className="w-1/2 max-w-md mx-auto">
              <label
                htmlFor="mp3"
                className={`flex items-center justify-center w-full h-12 px-4 transition duration-300 ease-in-out   border border-gray-300  rounded-md cursor-pointer ${fileNameMp3 ? ' text-zinc-500' : 'bg-white hover:bg-gray-50'
                  }`}
              >
                {fileNameMp3 ? (
                  <>
                    <span className="text-sm truncate flex-1">{fileNameMp3}</span>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        clearFileMp3();
                      }}
                      className="ml-2 focus:outline-none"
                    >
                      <Remove className="w-5 h-5" />
                    </button>
                  </>
                ) : (
                  <div className='flex gap-4 items-center'>
                    <FileUploadIcon isButton={true} className="w-5 h-5" />
                    <span className="text-sm">Upload Beat</span>
                  </div>
                )}
                <input
                  type="file"
                  id="mp3"
                  name="mp3"
                  onChange={handleFileUploadChange}
                  accept="audio/mpeg"
                  className="hidden"
                  required
                />
              </label>
            </div>
          </div>
          <div>
            <input
              type="text"
              id="tags"
              name="tags"
              value={formData.tags}
              onChange={handleInputChange}
              placeholder="Tags: Separate tags with commas"
              className="w-full px-3 py-2   border border-gray-300  rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <input
              type="text"
              id="duration"
              name="duration"
              value={formData.duration}
              onChange={handleInputChange}
              placeholder="duration: e.g., 3:45"
              className="w-full px-3 py-2   border border-gray-300  rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <input
              type="number"
              id="bpm"
              name="bpm"
              value={formData.bpm}
              onChange={handleInputChange}
              placeholder='BPM'
              className="w-full px-3 py-2   border border-gray-300  rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="w-full">
            <select
              id="status"
              name="status"
              value={formData.status}
              onChange={handleSelectChange}
              className="w-full bg-white   border border-gray-300  text-gray-700 py-2 px-3 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-gray-500"
            >
              <option value="for sale">For Sale</option>
              <option value="sold">Sold</option>
            </select>
          </div>
          <button
            type="submit"
            className={`w-full  py-2 px-4 rounded-md font-semibold focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${isUploading
              ? 'bg-[#E9D7FE] text-white cursor-not-allowed'
              : 'bg-[#000000] text-white hover:bg-[#28282B]'
              }`}
            disabled={isUploading}
          >
            {isUploading ? 'Uploading...' : 'Upload Beat'}
          </button>
        </form>
        <Toaster position="top-right" />
      </div>
    </div>
  );
};
export default FileUpload;