import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/AuthService';

const ProtectedRouteBuyer = ({ children }) => {
  const navigate = useNavigate();
  const user = authService.getCurrentUser();

  useEffect(() => {
    if (!user) {
        navigate('/login'); // Redirect to login if not authenticated
      } else if (user.user.role === 'admin') {
        navigate('/admin'); // Redirect to userhome if role is buyer
     
      }
    }, [user, navigate]);
  // If user is logged in, render the children
  return user && user.user.role === 'buyer' ? children : null;

};

export default ProtectedRouteBuyer;