import React from 'react'
function ErrorNotifcation() {
  return (
    <div>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#FEE4E2" />
        <g clip-path="url(#clip0_1102_5369)">
          <path d="M12 10V12M12 14H12.005M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12Z" stroke="#D92D20" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_1102_5369">
            <rect width="12" height="12" fill="white" transform="translate(6 6)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}
export default ErrorNotifcation